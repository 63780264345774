import {
  CmsNavigationChildComponent,
  CmsNavigationGroupComponent,
  CmsNavigationLinkComponent,
  CmsNavigationRootComponent,
} from '@/components/CmsInterfaces';
import { IconVariant } from '@/components/Icon';

export interface SidebarNavItem {
  title: string;
  shortTitle?: string;
  link?: string;
  items?: SidebarNavItem[];
  current: boolean;
  parentOfCurrent: boolean;
  id: string;
  icon?: IconVariant;
}

export interface SidebarNav {
  overview?: string;
  id: string;
  initial: string;
  items: SidebarNavItem[];
}

export function mapNavigationToSidebarItems(
  navigation: CmsNavigationRootComponent[],
  path: string
): SidebarNav {
  const items = { items: [], id: '', initial: '' };
  if (!navigation) {
    return items;
  }
  return navigation.reduce(
    (nav: SidebarNav, rootLevel: CmsNavigationRootComponent) => {
      const firstLevel = rootLevel.navigation.nodes || [];

      if (
        pathIsInNavigationLevel(path, firstLevel) ||
        pathIsInNavigationChildren(path, firstLevel) ||
        pathIsOverviewPage(path, rootLevel)
      ) {
        const children = getOverviewItem(path, rootLevel).concat(
          mapNavigationItems(rootLevel, path)
        );

        nav.items = children;
        nav.overview = rootLevel.overview && rootLevel.overview.path;
        nav.id = rootLevel.id;
        nav.initial = getInitialId(children, rootLevel.id);
      }
      return nav;
    },
    items
  );
}

function getInitialId(items: SidebarNavItem[], fallback: string): string {
  const currentParent = items.find((i) => i.parentOfCurrent);
  return currentParent ? currentParent.id : fallback;
}

function getOverviewItem(
  path: string,
  root: CmsNavigationRootComponent
): SidebarNavItem[] {
  if (root.overview) {
    return [
      {
        title: 'Übersicht',
        link: root.overview && root.overview.path,
        current: path === (root.overview && root.overview.path),
        parentOfCurrent: false,
        id: root.id,
      },
    ];
  }
  return [];
}

function pathIsOverviewPage(
  path: string,
  root: CmsNavigationRootComponent
): boolean {
  return path === (root.overview && root.overview.path);
}

function pathIsInNavigationLevel(
  path: string,
  level: CmsNavigationChildComponent[]
): boolean {
  return level.some(
    (item) => item.link !== undefined && item.link.path === path
  );
}

function pathIsInNavigationChildren(
  path: string,
  level: CmsNavigationChildComponent[]
): boolean {
  return level.some((item) => {
    return (
      item.navigation &&
      item.navigation.nodes &&
      pathIsInNavigationLevel(path, item.navigation.nodes)
    );
  });
}

function mapNavigationItems(
  rootLevel: CmsNavigationRootComponent,
  path: string
): SidebarNavItem[] {
  const items: CmsNavigationRootComponent[] = rootLevel.navigation.nodes || [];
  return items.map((child) => {
    const item: SidebarNavItem = {
      title: child.title,
      shortTitle: child.shortTitle,
      id: child.id,
      icon: child.icon,
      current: (child.link && child.link.path) === path,
      parentOfCurrent: false,
      ...(child.link
        ? { link: child.link.path }
        : { items: mapNavigationItemChildren(child, path) }),
    };

    item.parentOfCurrent = item.items
      ? item.items.some((i) => i.current)
      : false;
    return item;
  });
}

function mapNavigationItemChildren(
  item: CmsNavigationGroupComponent | CmsNavigationLinkComponent,
  path: string
): SidebarNavItem[] {
  return item.navigation && item.navigation.nodes
    ? item.navigation.nodes.map((child: CmsNavigationLinkComponent) => ({
        title: child.title,
        shortTitle: child.shortTitle,
        link: child.link.path,
        id: child.id,
        icon: child.icon,
        current: child.link.path === path,
        parentOfCurrent: false,
      }))
    : [];
}
