import createArrayFromNumber from '@/utilities/createArrayFromNumber';

export function generateRandomCouponsQueryParams(
  couponsAmount: number,
  numbersAmount?: number
) {
  const qtNumber = numbersAmount ? '-' + numbersAmount : '';
  return createArrayFromNumber(couponsAmount, 1)
    .map((_number) => `f${_number}=qt${qtNumber}`)
    .join('&');
}
