import HeaderGameTeaser from '@/components/HeaderGameTeaser';
import { GameConfig } from '@/config/game';
import { getGameLink } from '@/helpers/getGameLink';
import classnames from 'classnames';

export default function LayoutHeaderProduct({ game }: { game?: GameConfig }) {
  return (
    <header
      className={classnames(
        'LayoutHeader LayoutHeader--product LayoutHeader--no-campaign',
        `LayoutHeader--${game ? game.shortname : 'default'}`,
        {
          'LayoutHeader--alternative': !!game && !!game.alternativeDesign,
        }
      )}
    >
      <div className="LayoutHeader__background LayoutHeader__background--no-campaign">
        <div className="LayoutHeader__additionalBackground" />
      </div>
      <div className="LayoutHeader__content LayoutHeader__content--no-campaign">
        <HeaderGameTeaser game={game ? game.id : 0} link={getGameLink(game)} />
      </div>
    </header>
  );
}
