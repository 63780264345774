import { ButtonGroup } from '@/components/ButtonGroup';
import { Icon, IconVariant } from '@/components/Icon';
import Modal, {
  ModalContent,
  ModalHeader,
  ModalProps,
} from '@/components/Modal';
import { DrawRateConfig } from '@/config/game';
import React from 'react';

interface ModalQuickTipDrawRateButtonsProps extends ModalProps {
  drawRateList: DrawRateConfig[];
  headerIconVariant?: IconVariant;
  onClickButton: (numb: number) => void;
}

const ModalQuickTipDrawRateButtons: React.FC<
  React.PropsWithChildren<ModalQuickTipDrawRateButtonsProps>
> = ({
  id,
  headerIconVariant = IconVariant.Random,
  drawRateList,
  onClickButton,
}) => {
  const handleOnClickButton = (numb: string | number) => {
    Modal.close(id);
    onClickButton(+numb);
  };

  return (
    <Modal id={id}>
      <ModalHeader>
        <Icon variant={headerIconVariant} />
        <span>Einsatz pro Spielfeld</span>
      </ModalHeader>

      <ModalContent>
        <h4 className="h-text-center">
          Welchen Einsatz pro Spielfeld möchten Sie spielen?
        </h4>

        <ButtonGroup
          buttonList={drawRateList.map((item) => {
            const { value, label } = item;
            return {
              value,
              title: label,
            };
          })}
          onClickButton={handleOnClickButton}
        />
      </ModalContent>
    </Modal>
  );
};

export default ModalQuickTipDrawRateButtons;
