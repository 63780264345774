import Button from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import SidebarNavigationLevel from '@/components/SidebarNavigationLevel';
import navigation from '@/config/navigation';
import {
  SidebarNav,
  SidebarNavItem,
  mapNavigationToSidebarItems,
} from '@/helpers/mapNavigationToSidebarItems';
import classnames from 'classnames';
import React from 'react';

interface SidebarNavigationState {
  currentChild: string;
  listOpened: boolean;
}

export interface SidebarNavigationProps {
  path: string;
  backHref?: string;
  backLabel?: string;
  onChange: () => void;
}

export class SidebarNavigation extends React.Component<
  SidebarNavigationProps,
  SidebarNavigationState
> {
  private nav: SidebarNav;
  private sidebarNavigationRef: HTMLDivElement | null;

  constructor(props, state) {
    super(props, state);
    this.nav = mapNavigationToSidebarItems(navigation, this.props.path);
    this.state = {
      currentChild: this.nav.initial,
      listOpened: false,
    };
  }

  private handleOnAnyClick = ($event) => {
    if (
      SidebarNavigation.isMobile &&
      this.sidebarNavigationRef &&
      !this.sidebarNavigationRef.contains($event.target)
    ) {
      this.setState({ listOpened: false, currentChild: this.nav.initial });
    }
  };

  private static get isMobile(): boolean {
    return window ? window.innerWidth < 992 : false;
  }

  private get navList(): SidebarNavItem[] {
    return this.nav.items.reduce((list: SidebarNavItem[], item) => {
      return list.concat(item.items || [], item);
    }, []);
  }

  private getCurrentSectionTitle = (): string => {
    const currentMainItem = navigation.find(
      (nav) => nav?.overview?.path === this.navList[0].link
    );
    if (currentMainItem) {
      return currentMainItem.title;
    } else {
      return 'Über LOTTO.de';
    }
  };

  private getCurrentTitle = (fallback: string): string => {
    const currentItem = this.navList.find((item) => item.current);
    if (currentItem) {
      return currentItem.shortTitle || currentItem.title;
    } else {
      return fallback;
    }
  };

  private get triggerLabel(): string {
    const defaultLabel = 'Wo möchten Sie hin?';
    return this.state.listOpened
      ? defaultLabel
      : this.getCurrentTitle(defaultLabel);
  }

  private handleOnItemClick = (id: string): void => {
    this.setState({ currentChild: id }, this.props.onChange);
  };

  private handleOnTriggerClick = (): void => {
    this.setState((oldState) => ({
      listOpened: !oldState.listOpened,
    }));
  };

  private get backHref(): string {
    if (this.props.backHref) {
      return this.props.backHref;
    }
    const path = this.props.path.replace(/(^\/|\/$)/, '').split('/');
    if (path.length > 1) {
      return '/' + path.slice(0, -1).join('/');
    }
    return '/';
  }

  private get backLabel(): string {
    return this.props.backLabel || 'Zurück';
  }

  componentDidMount() {
    this.props.onChange();
    document.addEventListener('mousedown', this.handleOnAnyClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOnAnyClick);
  }

  render(): JSX.Element {
    return (
      <div
        className="SidebarNavigation"
        ref={(div) => {
          this.sidebarNavigationRef = div;
        }}
      >
        {this.navList.length > 0 ? (
          <>
            <button
              onClick={this.handleOnTriggerClick}
              className={classnames('SidebarNavigation__trigger', {
                'SidebarNavigation__trigger--active': this.state.listOpened,
              })}
            >
              <span>
                <span>{this.triggerLabel}</span>
                <Icon variant={IconVariant.ChevronDown} />
              </span>
            </button>
            <div
              className={classnames('SidebarNavigation__container', {
                'SidebarNavigation__container--opened': this.state.listOpened,
              })}
            >
              <nav
                className="SidebarNavigation__nav"
                aria-label="Seitenleistennavigation"
              >
                <SidebarNavigationLevel
                  id={this.nav.id}
                  root={this.nav.id}
                  level={1}
                  overview={this.nav.overview}
                  items={this.nav.items}
                  current={this.state.currentChild}
                  onClick={this.handleOnItemClick}
                  ariaLabel={`Bereichsnavigation ${this.getCurrentSectionTitle()}`}
                />
              </nav>
            </div>
          </>
        ) : (
          <LinkWrapper
            href={this.backHref}
            className="SidebarNavigation__back"
            tabIndex={-1}
          >
            <Button variant={ButtonVariant.Secondary}>
              <Icon variant={IconVariant.ArrowLeft} />
              <span>{this.backLabel}</span>
            </Button>
          </LinkWrapper>
        )}
      </div>
    );
  }
}

export default SidebarNavigation;
