import ClientOnly from '@/components/ClientOnly';
import SidebarNavigation from '@/components/SidebarNavigation';
import classnames from 'classnames';
import React from 'react';

enum Position {
  Sticky = 'sticky',
  Docked = 'docked',
  Static = 'static',
}

interface StickySidebarState {
  position: Position;
}

export interface StickySidebarProps {
  offsetTop?: number;
  path: string;
  backHref?: string;
  backLabel?: string;
}

export class StickySidebar extends React.Component<
  StickySidebarProps,
  StickySidebarState
> {
  private container: HTMLDivElement;
  static defaultProps: Partial<StickySidebarProps> = {
    offsetTop: 0,
  };

  constructor(props, state) {
    super(props, state);
    this.state = {
      position: Position.Static,
    };
  }

  componentDidMount(): void {
    this.addEventListeners();
    this.handleDocumentScroll();
  }

  private addEventListeners = (): void => {
    window.addEventListener('scroll', this.handleDocumentScroll, {
      passive: true,
    });
    window.addEventListener('resize', this.handleDocumentScroll, {
      passive: true,
    });
  };

  private get offsetTop(): number {
    return this.props.offsetTop || 0;
  }

  private get position(): Position {
    const nav = this.container && this.container.parentElement;
    const box = nav && nav.getBoundingClientRect();
    if (box && box.bottom <= this.container.clientHeight + this.offsetTop) {
      return Position.Docked;
    }
    if (box && box.top <= this.offsetTop) {
      return Position.Sticky;
    } else {
      return Position.Static;
    }
  }

  private handleDocumentScroll = () => {
    this.setState(() => ({
      position: this.position,
    }));
  };

  private handleOnNavigationChange = () => {
    setTimeout(() => this.handleDocumentScroll(), 0);
  };

  private get isSupportedBrowser(): boolean {
    return typeof window !== 'undefined' && !!(window as any).Notification;
  }

  render(): JSX.Element {
    const { path, backHref, backLabel } = this.props;

    return (
      <div
        className={classnames(
          'StickySidebar',
          `StickySidebar--${this.state.position}`
        )}
        ref={(el: HTMLDivElement) => {
          this.container = el;
        }}
      >
        <ClientOnly>
          <SidebarNavigation
            key={globalThis?.location?.pathname || ''}
            onChange={this.handleOnNavigationChange}
            path={path}
            backHref={backHref}
            backLabel={backLabel}
          />
        </ClientOnly>
      </div>
    );
  }
}

export default StickySidebar;
